import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Grid, Typography } from "@mui/material";
import { Helmet } from "react-helmet";

// Icon imports
import CircleIcon from "@mui/icons-material/Circle";

const Iletisim = () => {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="description" content="İletişim sayfası" />
        <title>İletişim - Ubicro</title>
        <link rel="canonical" href="https://www.ubicro.com/iletisim" />
      </Helmet>
      <Header />
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        style={{ height: 300, width: "100%", backgroundColor: "#fbfbfb" }}
      >
        <Typography variant="h2" component="h1">
          İletişim
        </Typography>
      </Grid>

      <Grid
        container
        alignItems="center"
        justifyContent="center"
        style={{ padding: 20 }}
      >
        <Grid container alignItems="center" direction="column" md={6} xs={12}>
          <Typography
            style={{
              color: "#5AC496",
              letterSpacing: 1,
              fontSize: 18,
              fontWeight: 100,
            }}
          >
            MERKEZ OFİSİMİZ
          </Typography>
          <Typography variant="h4" style={{ marginTop: 20 }}>
            Kağıthane, İstanbul, Türkiye
          </Typography>
        </Grid>

        <Grid
          container
          direction="column"
          md={3}
          xs={12}
          style={{ marginTop: 20, width: 300 }}
        >
          <Grid item>
            <Grid container direction="row" alignItems="center">
              <CircleIcon sx={{ color: "#5AC496", fontSize: 14 }} />
              <Typography style={{ fontWeight: 400, marginLeft: 8 }}>
                Adres
              </Typography>
            </Grid>
            <Typography
              style={{ color: "#717478", marginTop: 10, fontWeight: 100 }}
            >
              Ortabayır mahallesi, Dereyboyu caddesi
              <br />
              No: 6 Levent Life.
              <br />
              Daire: 38, Kat: 5
            </Typography>
          </Grid>

          <Grid item style={{ marginTop: 50 }}>
            <Grid container direction="row" alignItems="center">
              <CircleIcon sx={{ color: "#5AC496", fontSize: 14 }} />
              <Typography style={{ fontWeight: 400, marginLeft: 8 }}>
                İletişim
              </Typography>
            </Grid>
            <Typography
              style={{ color: "#717478", marginTop: 10, fontWeight: 100 }}
            >
              EMAİL: support@ubicro.com <br />
              TEL: 530 333 20 50
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid style={{ height: 200, width: " 100% " }} />
      <Footer />
    </div>
  );
};

export default Iletisim;
